/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import useReactRouter from 'use-react-router';
import { ResponseBody } from '../../../../../../frontend-api/src/handlers/public/public-reservations-new-handler';
import { Course } from '../../../../@interfaces/course';
import {
  calcTotalMinutesReqired,
  CourseSlotSetting,
  getMinitesRequiredFields,
} from '../../../../@interfaces/course-slot-setting';
import { CustomerLastInputOverview } from '../../../../@interfaces/customer-last-input';
import { ResourceGroupWithMember } from '../../../../@interfaces/resource/resource-group';
import { Shop } from '../../../../@interfaces/shop';
import { Workspace } from '../../../../@interfaces/workspace';
import { PublicWorkspaceSetting } from '../../../../@interfaces/workspace-setting';
import { changeReservation, reserve } from '../../../../api/use-reservation';
import { SelectedReserveInfo } from '../../../../components/Shared';
import {
  Field,
  FieldResponseValue,
  FormResponse,
  FormSetting,
  OptionValue,
} from '../../../../core/types/reservation-form-types';
import { requiredConstantTargets } from '../../../../core/types/reservation-resource-types';
import { DateTime } from '../../../../core/types/reservation-types';
import useStorage from '../../../../hooks/use-storage';
import {
  getLineSessionKeyFromParam,
  getLineUserFromParam,
  useLineUser,
} from '../../../../models/line';
import { getInflowSource } from '../../../../utils/inflow-source';
import {
  trackConversionFunnelCourse,
  useConversionFunnelCourse,
} from '../../../insight/hooks/useConversionFunnelCourse';
import {
  getConversionUuid,
  removeConversionUuid,
} from '../../../insight/utils/conversionUuid';
import {
  createDefaultResourceResponse,
  getResourceStorageKey,
} from '../../routes/FormPage';
import { Form } from './Form';
import { buildBackUrl } from './form-utils';

const styles = {
  selectOption: css({
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#172B4D',
  }),
  reserveChangeTitle: css({
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '24px 0 0',
  }),
};

type ReservationFormProps = {
  workspace: Workspace | undefined;
  workspaceSetting: PublicWorkspaceSetting | undefined;
  shop: Shop | undefined;
  course: Course | undefined;
  dateTime: DateTime;
  formSetting: FormSetting | undefined;
  formHeader: string | undefined;
  formFooter: string | undefined;
  courseSlotSetting: CourseSlotSetting | undefined;
  groups: ResourceGroupWithMember[];
  formResponse: FormResponse;
  primaryColor: string;
  reservationKey: string | null;
  onChangeValue?: (field: Field, newValues: FieldResponseValue[]) => void;
  onChangeValues?: (
    fields: { field: Field; newValues: FieldResponseValue[] }[]
  ) => void;
  customerLastInput?: CustomerLastInputOverview;
};

export default function ReservationForm(props: ReservationFormProps) {
  const {
    workspace,
    workspaceSetting,
    shop,
    course,
    dateTime,
    formSetting,
    formFooter,
    courseSlotSetting,
    groups,
    formResponse,
    primaryColor,
    reservationKey,
    onChangeValue,
    onChangeValues,
    customerLastInput,
  } = props;

  const location = useLocation();
  const inflowSrc = getInflowSource(location.search);
  const [submitting, setSubmitting] = useState(false);
  const [lineUser, setLineUser] = useLineUser();
  const { history } = useReactRouter();
  const [resourceResponse, setResourceResponse] = useStorage(
    getResourceStorageKey(workspace?.uid || '', course?.uid || ''),
    createDefaultResourceResponse(),
    'session'
  );

  useConversionFunnelCourse(
    workspace?.uid,
    shop?.uid,
    inflowSrc,
    course?.uid,
    'form',
    reservationKey === null // 予約変更のときはコンバージョン記録をしない
  );

  const { minitesRequiredFields, nonMinitesRequiredFields } = useMemo(() => {
    const fields =
      formSetting?.fields.filter((field) =>
        field.condition ? field.condition.public.visibled : true
      ) || [];
    const minitesRequiredFields = getMinitesRequiredFields(
      fields || [],
      courseSlotSetting?.shopCourseSetting
    );
    const nonMinitesRequiredFields = fields.filter(
      (f) => !minitesRequiredFields.includes(f)
    );
    return {
      minitesRequiredFields,
      nonMinitesRequiredFields,
    };
  }, [formSetting?.fields, courseSlotSetting?.shopCourseSetting]);

  const minutesRequiredFieldsPreview = useMemo(() => {
    if (reservationKey) {
      return null;
    }
    return (
      <div style={{ marginTop: '8px' }}>
        {minitesRequiredFields.map((field, index) => {
          if (field.type !== 'checkbox' && field.type !== 'radio') {
            return null;
          }
          const formValue = formResponse.fields.find(
            (r) => r.uid === field.uid
          );
          const options =
            formValue?.values.map(
              (value) =>
                field.options.find((o) => o.uid === (value as OptionValue)?.uid)
                  ?.text
            ) || [];
          return (
            <div key={index} css={styles.selectOption}>
              {field.name}: <strong>{options.join(', ')}</strong>
            </div>
          );
        })}
      </div>
    );
  }, [reservationKey, minitesRequiredFields, formResponse.fields]);

  const selectedMembersPreview = useMemo(() => {
    return (
      <div style={{ marginTop: '8px' }}>
        {requiredConstantTargets(
          courseSlotSetting?.resourceConstantSetting?.constants || []
        ).map((constant, index) => {
          const group = groups.find(
            (group) => group.id === constant.resourceGroupId
          );
          const resource = resourceResponse.resources.find(
            (r) => r.targetUid === constant.uid
          );
          const selectedResource = group?.members.find(
            (r) => r.id === resource?.resourceId
          );
          return (
            <div key={index} css={styles.selectOption}>
              {group?.name}:{' '}
              <strong>{selectedResource?.name || '指定なし'}</strong>
            </div>
          );
        })}
      </div>
    );
  }, [
    courseSlotSetting?.resourceConstantSetting?.constants,
    groups,
    resourceResponse.resources,
  ]);

  if (!workspace) {
    return <div>ワークスペース情報が取得できませんでした。</div>;
  }
  if (!shop) {
    return <div>店舗情報が取得できませんでした。</div>;
  }
  if (!course) {
    return <div>コース情報が取得できませんでした。</div>;
  }
  if (!formSetting) {
    return <div>予約フォーム情報が取得できませんでした。</div>;
  }

  const handleSubmitForm = (e: React.FormEvent) => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    reserve(
      workspace.uid,
      shop.uid,
      course.uid,
      dateTime,
      formResponse,
      resourceResponse,
      inflowSrc,
      lineUser || getLineUserFromParam(),
      getLineSessionKeyFromParam()
    )
      .then(async (response) => {
        if (!response.ok) {
          if (response.status === 400) {
            const body = await response.json();
            if (body.errorCode === 'limitation') {
              alert(
                '選択頂いた時間で予約できませんでした。予約可能な時間帯を過ぎました。お手数ですが別の日時を選んでご予約ください。'
              );
              history.push(buildBackUrl(workspace.uid, shop.uid, course.uid));
              return;
            } else {
              alert(
                '選択頂いた時間で予約できませんでした。他の方が先に予約された可能性があります。お手数ですが別の日時を選んでご予約ください。'
              );
              history.push(buildBackUrl(workspace.uid, shop.uid, course.uid));
              return;
            }
          }

          if (response.status === 408) {
            alert(
              '混み合っているため予約できませんでした。時間をあけて再度お試しください。'
            );
            history.push(buildBackUrl(workspace.uid, shop.uid, course.uid));
            return;
          }

          return response.text();
        }
        const data: ResponseBody = await response.json();
        const searchParams = new URLSearchParams(location.search);
        if (inflowSrc) {
          searchParams.set('_src', inflowSrc);
        }
        if (reservationKey) {
          searchParams.set('reservationKey', reservationKey);
        }
        if (data.reservationId) {
          searchParams.set('reservationId', String(data.reservationId));
        }

        // 予約完了コンバージョン記録処理
        trackConversionFunnelCourse(workspace.uid, shop.uid, course.uid, {
          conversionUuid: getConversionUuid(),
          inflowSource: inflowSrc,
          pageType: 'reserved',
        })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            // 連続した予約は別セッションとみなしたいので、ここでクリアしておく
            removeConversionUuid();
            window.location.href = `thanks?${searchParams.toString()}`;
          });
      })
      .then((errorText) => {
        if (errorText != null) {
          alert(errorText);
        }
      })
      .catch((e) => {
        alert(e);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSubmitFormChangeReservation = (e: React.FormEvent) => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    changeReservation(
      workspace.uid,
      shop.uid,
      course.uid,
      dateTime,
      reservationKey,
      resourceResponse
    )
      .then(async (response) => {
        if (!response.ok) {
          if (response.status === 400) {
            const body = await response.json();
            if (body.errorCode === 'limitation') {
              alert(
                '選択頂いた時間で予約できませんでした。予約可能な時間帯を過ぎました。お手数ですが別の日時を選んでご予約ください。'
              );
              history.push(buildBackUrl(workspace.uid, shop.uid, course.uid));
              return;
            } else {
              alert(
                '選択頂いた時間で予約できませんでした。他の方が先に予約された可能性があります。お手数ですが別の日時を選んでご予約ください。'
              );
              history.push(buildBackUrl(workspace.uid, shop.uid, course.uid));
              return;
            }
          }

          if (response.status === 408) {
            alert(
              '混み合っているため予約できませんでした。時間をあけて再度お試しください。'
            );
            history.push(buildBackUrl(workspace.uid, shop.uid, course.uid));
            return;
          }

          return response.text();
        }

        const searchParams = new URLSearchParams(location.search);
        if (inflowSrc) {
          searchParams.set('_src', inflowSrc);
        }
        if (reservationKey) {
          searchParams.set('reservationKey', reservationKey);
        }
        window.location.href = `thanks?${searchParams.toString()}`;
      })
      .catch((e) => {
        alert(e);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div style={{ padding: '0 24px' }}>
      {reservationKey && (
        <h2 css={styles.reserveChangeTitle} style={{ color: primaryColor }}>
          予約日時を変更する
        </h2>
      )}
      <div>
        <SelectedReserveInfo
          name={course.name}
          imagePath={course.imagePath}
          dateTime={dateTime}
          primaryColor={primaryColor}
          requiredMinutes={
            courseSlotSetting?.shopCourseSetting?.showsMinutesRequired &&
            calcTotalMinutesReqired(
              formResponse,
              courseSlotSetting?.shopCourseSetting
            )
          }
        >
          {minutesRequiredFieldsPreview}
          {selectedMembersPreview}
        </SelectedReserveInfo>
      </div>
      <Form
        workspaceSetting={workspaceSetting}
        fields={nonMinitesRequiredFields}
        formFooter={formFooter}
        formResponse={formResponse}
        onChangeValue={onChangeValue}
        onChangeValues={onChangeValues}
        onSubmitForm={handleSubmitForm}
        onSubmitFormChangeReservation={handleSubmitFormChangeReservation}
        submitting={submitting}
        primaryColor={primaryColor}
        reservationKey={reservationKey}
        customerLastInput={customerLastInput}
      />
    </div>
  );
}

/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import useCourse from '../api/use-course';
import { useCustomDomainAttachedShop } from '../api/use-custom-domain-attached-shop';
import useReservation from '../api/use-reservation';
import { DomainError, validDomain } from '../components/DomainError';
import { Header, Loading, SelectedReserveInfo } from '../components/Shared';
import useUserScript from '../hooks/use-user-script';
import { DEFAULT_PRIMARY_COLOR } from '../models/theme';

type PageParams = {
  workspaceUid: string;
  shopUid: string;
  courseUid: string;
  reservationKey: string;
};

const styles = {
  reservedContainer: css`
    padding: 0 24px;
  `,
  reserveChangeTitle: css`
    font-size: 20px;
    font-weight: bold;
    padding: 24px 0 0;
  `,
  maxWidth: css`
    max-width: 700px;
    margin: 0 auto;
  `,
};

export default function ReservationPage() {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopUid, courseUid, reservationKey } = match.params;

  const { workspaceSetting, shop, shopSetting, course, isLoadingCourse } =
    useCourse(workspaceUid, shopUid, courseUid);

  const { data: shopCustomDomain, isLoading: isLoadingShopCustomDomain } =
    useCustomDomainAttachedShop(workspaceUid, shopUid);

  useUserScript(workspaceSetting, shopSetting);
  const primaryColor =
    shopSetting?.primaryColor ||
    workspaceSetting?.primaryColor ||
    DEFAULT_PRIMARY_COLOR;

  const { originalDateTime, isCanceled, isLoadingReservation } = useReservation(
    workspaceUid,
    shopUid || '',
    courseUid || '',
    reservationKey
  );

  if (
    !isLoadingCourse &&
    !validDomain(workspaceSetting?.domain) &&
    !isLoadingShopCustomDomain &&
    !validDomain(shopCustomDomain?.fqdn ?? null)
  ) {
    return <DomainError domain={workspaceSetting?.domain} />;
  }

  const isLoading = isLoadingCourse && isLoadingReservation;

  return isLoading ? (
    <Loading primaryColor="#777" />
  ) : (
    <div
      css={[
        styles.maxWidth,
        css`
          background: #f2f2f2;
        `,
      ]}
    >
      <div>
        <Header
          logoPath={shopSetting?.logoPath || workspaceSetting?.logoPath}
          shopName={shop?.name || ''}
          primaryColor={primaryColor}
        />
        <div css={styles.reservedContainer}>
          {originalDateTime && (
            <>
              <h2
                css={[
                  styles.reserveChangeTitle,
                  css`
                    color: ${primaryColor};
                  `,
                ]}
              >
                予約内容
              </h2>
              <div>
                <SelectedReserveInfo
                  name={course?.name || ''}
                  imagePath={course?.imagePath || null}
                  dateTime={originalDateTime}
                  primaryColor={primaryColor}
                />

                {isCanceled && <p>この予約はキャンセルされました。</p>}
                {!isCanceled && (
                  <>
                    <p>予約の日時変更・キャンセルはこちら</p>
                    <ul
                      css={css`
                        margin-top: 20px;
                      `}
                    >
                      <li>
                        <Link
                          to={`/a/${workspaceUid}/shops/${shopUid}/courses/${courseUid}?reservationKey=${reservationKey}`}
                        >
                          日時の変更
                        </Link>
                      </li>
                      <li
                        css={css`
                          margin-top: 10px;
                        `}
                      >
                        <Link
                          to={`/a/${workspaceUid}/shops/${shopUid}/courses/${courseUid}/cancel?reservationKey=${reservationKey}`}
                        >
                          キャンセル
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

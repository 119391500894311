import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ResponseBody } from '../../../frontend-api/src/handlers/public/public-reservation-table-handler';
import { ResourceGroupWithMember } from '../../../frontend-api/src/interfaces/resource/resource-group';
import { Course } from '../@interfaces/course';
import { CourseSlotSetting } from '../@interfaces/course-slot-setting';
import { Shop } from '../@interfaces/shop';
import { PublicShopSetting } from '../@interfaces/shop-setting';
import { Workspace } from '../@interfaces/workspace';
import { PublicWorkspaceSetting } from '../@interfaces/workspace-setting';
import { FormSetting } from '../core/types/reservation-form-types';
import {
  ResourceResponse,
  SelectedOptionalField,
} from '../core/types/reservation-resource-types';
import {
  DateRange,
  ReservationTable,
  toDateStringByDate,
} from '../core/types/reservation-types';
import { API_END_POINT } from './api';

export default function useReservationTable(
  workspaceUid: string,
  shopUid: string,
  courseUid: string,
  range: DateRange,
  reservationKey: string | undefined,
  forceMinutesRequired: number | undefined,
  resourceResponse: ResourceResponse,
  selectedOptionalFields: SelectedOptionalField[]
) {
  const history = useHistory();
  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaceSetting, setWorkspaceSetting] =
    useState<PublicWorkspaceSetting>();
  const [shop, setShop] = useState<Shop>();
  const [shopSetting, setShopSetting] = useState<PublicShopSetting>();
  const [course, setCourse] = useState<Course>();
  const [reservationTable, setReservationTable] = useState<ReservationTable>();
  const [formSetting, setFormSetting] = useState<FormSetting>();
  const [courseSlotSetting, setCourseSlotSetting] =
    useState<CourseSlotSetting>();
  const [groups, setGroups] = useState<ResourceGroupWithMember[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // startDateがクエリパラメータに含まれていない場合は日付範囲は確定値でないため、
    // API側で日付調整ありのリクエストとする
    const dateAdjustmentRequired = new URLSearchParams(
      history.location.search
    ).has('startDate')
      ? false
      : true;
    const params = {
      startDate: toDateStringByDate(range.start),
      endDate: toDateStringByDate(range.end),
      reservationKey,
      forceMinutesRequired,
      resourceResponseJson: JSON.stringify(resourceResponse),
      selectedOptionalFieldsJson: JSON.stringify(selectedOptionalFields),
      dateAdjustmentRequired,
    };
    setReservationTable(undefined);
    setLoading(true);
    axios
      .get(
        `${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/courses/${courseUid}/reservation-table`,
        {
          params,
        }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setWorkspace(response.data.workspace);
        setWorkspaceSetting(response.data.workspaceSetting);
        setShop(response.data.shop);
        setShopSetting(response.data.shopSetting);
        setCourse(response.data.course);
        setReservationTable(response.data.reservationTable);
        setFormSetting(response.data.formSetting);
        setCourseSlotSetting(response.data.courseSlotSetting);
        setGroups(response.data.groups);
      })
      .finally(() => {
        setLoading(false);
      });
    // 予約枠空き状況を取得するために必要な依存のみの入れることで、
    // 無駄なリクエストを外す＋オブジェクト型の依存をJSON化して厳密に判定
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workspaceUid,
    shopUid,
    courseUid,
    reservationKey,
    forceMinutesRequired,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(range),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(resourceResponse),
  ]);
  return {
    workspace,
    workspaceSetting,
    shop,
    shopSetting,
    course,
    reservationTable,
    formSetting,
    courseSlotSetting,
    groups,
    isLoadingReservationTable: loading,
  };
}

import { FormResponse } from '../core/types/reservation-form-types';
import { DateFormat, ReservationTable } from '../core/types/reservation-types';
import { Course } from './course';
import { RichCustomer } from './customer';
import { InflowSource } from './inflow-source';
import { ReservationResourceWithResource } from './resource/reservation-resource';

export const INFLOW_SOURCE_INTERNAL = 'internal';
export const INFLOW_SOURCE_DIRECT = 'direct';
export const INFLOW_SOURCE_UNKNOWN = 'unknown';

export const InflowSourceLabels: { [key: string]: string } = {
  internal: '内部',
  direct: 'ダイレクト',
  unknown: '不明',
  gmb: 'Googleビジネスプロフィール',
  hp: 'ホームページ',
};

export type NewReservation = {
  shopId: number;
  courseId: number;
  reservationDate: DateFormat;
  reservationHour: number;
  reservationMinute: number;
  formResponse: FormResponse;
  note: string;
  inflowSource: string;
};

export type Reservation = NewReservation & {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
};

export type SlimReservation = {
  id: number;
  shopId: number;
  courseId: number;
  reservationDate: DateFormat;
  reservationHour: number;
  reservationMinute: number;
  formResponse: FormResponse;
};

export type RichReservation = Reservation & {
  courseName: string;
  inflowSourceLabel: string;
  selectedLabelIds: number[];
  selectedResources: ReservationResourceWithResource[];
  minutesRequired?: number;
  customer?: RichCustomer;
  lineMessagingChannelId?: number;
};

export type CourseReservationTable = ReservationTable & {
  course: Course;
  minutesRequired?: number;
};

export function getInflowSourceLabel(
  inflowSourceValue: string,
  sources: InflowSource[]
): string {
  return (
    sources.find((s) => s.uid == inflowSourceValue)?.name ||
    InflowSourceLabels[inflowSourceValue] ||
    inflowSourceValue
  );
}

export type ShopReportGroupingType =
  | 'inflowSource'
  | 'createdHour'
  | 'reservationHour'
  | 'createdDayOfWeek'
  | 'reservationDayOfWeek'
  | 'course'
  | 'label';

export type ShopReport = {
  shopId: number | undefined;
  shopName: string;
  shopUid: string;
  webTotal: number;
  internalTotal: number;
  total: number;
  grouping: GroupingDetails | undefined;
};

export type GroupingDetails = {
  name: string;
  details: GroupingDetail[];
};

export type GroupingDetail = {
  label: string;
  webTotal: number | undefined;
  internalTotal: number | undefined;
  total: number | undefined;
};

export type SummarizedInflowSource = {
  inflowSource: string;
  groupingValue: string;
  total: number;
};

export type SummarizedShopInflowSource = {
  shopId: number;
  shopName: string;
  inflowSource: string;
  groupingValue: string;
  total: number;
};

import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { log } from '../api/use-logging';
import { getLineUserFromParam, useLineUser } from '../models/line';

export const useTrackLocation = () => {
  const location = useLocation();
  const [lineUser, setLineUser] = useLineUser();
  useEffect(() => {
    try {
      const lineUserIdFromStorage = lineUser?.userId;
      const lineUserIdFromParam = getLineUserFromParam()?.userId;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      log(
        `Show URL: ${document.location.href}, lineUserIdFromStorage=${
          lineUserIdFromStorage || ''
        }, lineUserIdFromParam=${lineUserIdFromParam || ''}`
      );
    } catch (e) {
      console.error(e);
    }
  }, [location]);
};

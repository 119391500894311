/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { google, ics } from 'calendar-link';
import { useEffect } from 'react';
import { Course } from '../../../@interfaces/course';
import { CourseSlotSetting } from '../../../@interfaces/course-slot-setting';
import { Shop } from '../../../@interfaces/shop';
import { PublicShopSetting } from '../../../@interfaces/shop-setting';
import { fetchTel, isSmsSendableTel } from '../../../@interfaces/sms/base';
import { Workspace } from '../../../@interfaces/workspace';
import { PublicWorkspaceSetting } from '../../../@interfaces/workspace-setting';
import { BannerImageContent } from '../../../components/BannerImageContent';
import { SelectedReserveInfo } from '../../../components/Shared';
import {
  AnyField,
  FieldResponseValue,
  FormResponse,
  FormSetting,
  getSpecialFieldValue,
  publicFields,
} from '../../../core/types/reservation-form-types';
import {
  DateTime,
  toDayjsByDateTime,
} from '../../../core/types/reservation-types';
import { lineBreakToBrAndHtml } from '../../../utils/browsers';
import { margeParams } from '../../../utils/query-storage';
import { clearStorage } from '../routes/FormPage';

const styles = {
  commonPadding: css`
    padding: 0 16px;
  `,
  reserveButton: (primaryColor: string) =>
    css`
      display: block;
      color: #fff;
      background-color: ${primaryColor};
      font-size: 18px;
      width: 70%;
      max-width: 350px;
      padding: 16px 0;
      margin: 24px auto;
      border-radius: 56px;
      border: none;
      text-align: center;
      @media (max-width: 320px) {
        font-size: 14px;
      }
    `,
  title: (primaryColor: string) =>
    css`
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 24px;
      line-height: 1.3;
    `,
  description: css`
    font-size: 14px;
    text-align: center;
  `,
  secondDescription: css`
    margin-top: 16px;
  `,
  reserveDate: css`
    color: #ff0089;
    font-size: 12px;
    font-weight: bold;
  `,
  calendar: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '0 0 16px 0',
  }),
  calendarLink: (primaryColor: string) =>
    css`
      color: ${primaryColor};
      font-size: 15px;
      margin-right: 15px;
      padding: 10px 10px 10px 5px;
    `,
  calendarIcon: (primaryColor: string) =>
    css`
      color: ${primaryColor};
      vertical-align: middle;
      height: 24px;
      width: 24px;
      fill: currentColor;
    `,
  thanksMessage: (primaryColor: string) =>
    css`
      font-size: 14px;
      text-align: left;
      margin-bottom: 24px;
      line-height: 1.5;
      padding: 0 8px;
      a {
        color: ${primaryColor};
        text-decoration: underline;
      }
    `,
};

const calendarIcon = (
  <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"></path>
);

type Props = {
  workspace: Workspace | undefined;
  workspaceSetting: PublicWorkspaceSetting | undefined;
  shop: Shop | undefined;
  course: Course | undefined;
  dateTime: DateTime;
  endDateTime: DateTime;
  formSetting: FormSetting | undefined;
  courseSlotSetting: CourseSlotSetting | undefined;
  formResponse: FormResponse;
  primaryColor: string;
  reservationKey: string | null;
  widgetMode: boolean;
  shopSetting: PublicShopSetting | undefined;
};

const buildValueString = (
  field: AnyField,
  values: FieldResponseValue[] | undefined
) => {
  return (
    values
      ?.map((value) => {
        if (typeof value === 'string') {
          return value;
        } else if (field.type === 'checkbox' || field.type === 'radio') {
          return (
            field.options.find((option) => option.uid === value.uid)?.text ||
            value
          );
        } else {
          return value;
        }
      })
      .join(', ') || ''
  );
};

export default function ReservationFormCompleted(props: Props) {
  const {
    workspace,
    workspaceSetting,
    shop,
    course,
    dateTime,
    endDateTime,
    formSetting,
    courseSlotSetting,
    formResponse,
    primaryColor,
    reservationKey,
    widgetMode,
    shopSetting,
  } = props;

  useEffect(() => {
    if (!workspace) {
      return;
    }
    clearStorage(workspace.uid);
  }, [workspace]);

  if (!workspace) {
    return <div>ワークスペース情報が取得できませんでした。</div>;
  }
  if (!shop) {
    return <div>店舗情報が取得できませんでした。</div>;
  }
  if (!course) {
    return <div>コース情報が取得できませんでした。</div>;
  }
  if (!formSetting) {
    return <div>予約フォーム情報が取得できませんでした。</div>;
  }

  const event = {
    title: `予約：${course.name}`,
    start: `${dateTime.date.year}-${dateTime.date.month}-${dateTime.date.date} ${dateTime.time.hour}:${dateTime.time.minute}:00`,
    end: `${dateTime.date.year}-${dateTime.date.month}-${dateTime.date.date} ${dateTime.time.hour}:${dateTime.time.minute}:00`,
    description: `${
      workspaceSetting?.shopLabel
        ? '【' + workspaceSetting.shopLabel + '】'
        : '【ご予約店舗】'
    }
${shop.name}

${
  workspaceSetting?.courseLabel
    ? '【' + workspaceSetting.courseLabel + '】'
    : '【コース】'
}
${course.name}

【ご予約日時】
${toDayjsByDateTime(dateTime).locale('ja').format('YYYY年M月D日 (dd)')}

${publicFields(formSetting.fields)
  .map((field) => {
    const values = formResponse.fields.find((f) => f.uid === field.uid)?.values;
    const valueString = buildValueString(field, values);
    return `【${field.name}】
${valueString}

`;
  })
  .join('')}
`,
  };

  const googleLink = google(event);
  const icsLink = ics(event);
  const email = getSpecialFieldValue(formSetting, formResponse, 'email');

  const continueReserveUrlFullUrl = new URL(
    margeParams(`/a/${workspace.uid}/shops/${shop.uid}`)
  );
  const continueReserveUrl = `${continueReserveUrlFullUrl.pathname}${continueReserveUrlFullUrl.search}`;

  const sendsSms: boolean =
    !!shopSetting?.canSendSms &&
    isSmsSendableTel(
      fetchTel({ formResponse }, { settingJson: formSetting }) ?? ''
    );

  return (
    <div css={styles.commonPadding}>
      <div>
        <SelectedReserveInfo
          name={course.name}
          imagePath={course.imagePath}
          dateTime={dateTime}
          primaryColor={primaryColor}
        />
      </div>
      <div css={styles.title(primaryColor)}>
        {reservationKey
          ? '上記の日時に予約を変更しました。'
          : courseSlotSetting?.thanksTitle
          ? courseSlotSetting.thanksTitle
          : course.thanksTitle
          ? course.thanksTitle
          : '上記の日時で予約完了しました。'}
      </div>
      <div css={styles.thanksMessage(primaryColor)}>
        {courseSlotSetting?.thanksMessage
          ? lineBreakToBrAndHtml(courseSlotSetting.thanksMessage)
          : course.thanksMessage && lineBreakToBrAndHtml(course.thanksMessage)}
      </div>
      {shopSetting?.displayBanner &&
      shopSetting?.bannerPath &&
      shopSetting?.displayReservationBanner ? (
        <div
          css={css`
            margin: auto;
            display: block;
            padding: 0 0 16px 0;
          `}
        >
          <BannerImageContent shopSetting={shopSetting} />
        </div>
      ) : null}
      <div css={styles.calendar}>
        <a href={googleLink} target="_blank" rel="noreferrer noopener">
          <svg css={styles.calendarIcon(primaryColor)}>{calendarIcon}</svg>
          <span css={styles.calendarLink(primaryColor)}>
            日程をGoogleカレンダーに登録
          </span>
        </a>
      </div>
      <div css={styles.calendar}>
        <a href={icsLink} target="_blank" rel="noreferrer noopener">
          <svg css={styles.calendarIcon(primaryColor)}>{calendarIcon}</svg>
          <span css={styles.calendarLink(primaryColor)}>
            日程をその他のカレンダーに登録
          </span>
        </a>
      </div>
      {email && (
        <div>
          <p css={styles.description}>
            お申し込みいただいたメールアドレス宛に予約内容詳細メールを
            {sendsSms && '、電話番号宛にSMSを'}
            送付しております。ご確認ください。
          </p>
          <p css={[styles.description, styles.secondDescription]}>
            変更・キャンセルにつきましてもメール{sendsSms && '、またはSMS'}
            に記載されているURLよりご変更をお願いします。
          </p>
        </div>
      )}
      {!reservationKey &&
        Boolean(course.isShowContinueReserve) &&
        !widgetMode && (
          <div>
            <a
              href={continueReserveUrl}
              css={styles.reserveButton(primaryColor)}
            >
              続けて予約する
            </a>
          </div>
        )}
    </div>
  );
}

/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { DisplayMode } from '../../../@interfaces/course-slot-setting';
import {
  isReservationTablePreviewData,
  PREVIEW_MESSAGE_TYPE_RESERVATION_TABLE,
} from '../../../@interfaces/preview/reservation-table-preview';
import { ReservationTableService } from '../../../core/services/reservation-table-service';
import {
  createDateRange,
  DateFormat,
  DateRange,
  ReservationTable,
  SlotSetting,
} from '../../../core/types/reservation-types';
import { DEFAULT_PRIMARY_COLOR } from '../../../models/theme';
import {
  CourseSlotSettingForReservationTable,
  ReservationTableContent,
} from '../../shop-course/components/ReservationTableContent';

const styles = {
  container: css`
    padding: 20px;
    max-width: 692px;
    margin: 0 auto;
    margin-bottom: 100px;
  `,
  options: css`
    padding: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
  `,
  optionsInfo: css`
    font-size: 12px;
    color: #666;
    margin-bottom: 10px;
  `,
};

const DEFAULT_DATE_RANGE = createDateRange(
  dayjs().format('YYYY-MM-DD') as DateFormat,
  dayjs().add(6, 'day').format('YYYY-MM-DD') as DateFormat
);

export default function ReservationTablePreviewPage() {
  const [reservationTable, setReservationTable] = useState<ReservationTable>({
    dateRange: DEFAULT_DATE_RANGE,
    dates: [],
  });
  const [dateRange, setDateRange] = useState<DateRange>(DEFAULT_DATE_RANGE);
  const [slotSetting, setSlotSetting] = useState<SlotSetting>();
  const [displayMode, setDisplayMode] = useState<DisplayMode>('default');
  const [showsSlotCapacity, setShowsSlotCapacity] = useState(false);
  const [hidesNoSlotDate, setHidesNoSlotDate] = useState(false);

  useEffect(() => {
    window.addEventListener('message', function (e) {
      if (
        process.env.REACT_APP_PREVIEW_MSG_SENDER_ORIGIN === e.origin &&
        e.data?.type === PREVIEW_MESSAGE_TYPE_RESERVATION_TABLE
      ) {
        // プレビューで項目の変更を表示するための処理
        const { data } = e.data;
        if (isReservationTablePreviewData(data)) {
          const { rules } = data;
          const slotSetting: SlotSetting = {
            rules: rules,
            isHoliday: () => false,
            courseId: -1,
          };
          setSlotSetting(slotSetting);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!slotSetting) {
      return;
    }
    const newTable = new ReservationTableService().buildReservationTable(
      slotSetting,
      dateRange,
      []
    );
    setReservationTable(newTable);
  }, [dateRange, slotSetting]);

  const courseSlotSetting: CourseSlotSettingForReservationTable =
    useMemo(() => {
      const courseSlotSetting: CourseSlotSettingForReservationTable = {
        showsSlotCapacity: showsSlotCapacity ? 1 : 0,
        hidesNoSlotDate: hidesNoSlotDate,
        shopCourseSetting: {
          minutesRequired: undefined,
        },
      };
      return courseSlotSetting;
    }, [hidesNoSlotDate, showsSlotCapacity]);

  return (
    <div>
      <div css={styles.options}>
        <div css={styles.optionsInfo}>
          ※
          下記設定はプレビュー時のみ反映されます。公開された画面の表示設定は別途コース詳細設定から行ってください。
        </div>
        <div>
          <input
            id="displayMode"
            type="checkbox"
            checked={displayMode === 'table'}
            onChange={(e) => {
              setDisplayMode(e.target.checked ? 'table' : 'default');
            }}
          />
          <label htmlFor="displayMode">テーブル形式で表示</label>
        </div>
        <div>
          <input
            id="showsSlotCapacity"
            type="checkbox"
            checked={showsSlotCapacity}
            onChange={(e) => {
              setShowsSlotCapacity(e.target.checked);
            }}
          />
          <label htmlFor="showsSlotCapacity">予約可能数を表示</label>
        </div>
        <div>
          <input
            id="hidesNoSlotDate"
            type="checkbox"
            checked={hidesNoSlotDate}
            onChange={(e) => {
              setHidesNoSlotDate(e.target.checked);
            }}
          />
          <label htmlFor="hidesNoSlotDate">予約枠がない日付を隠す</label>
        </div>
      </div>
      <div css={styles.container}>
        <ReservationTableContent
          reservationTable={reservationTable}
          dateRange={dateRange}
          primaryColor={DEFAULT_PRIMARY_COLOR}
          courseSlotSetting={courseSlotSetting}
          widgetMode={false}
          displayMode={displayMode}
          previewMode={true}
          onChangeDateRange={setDateRange}
        />
      </div>
    </div>
  );
}

import { SlotRule } from '../../core/types/reservation-types';

export const PREVIEW_MESSAGE_TYPE_RESERVATION_TABLE =
  'updateReservationTablePreview';

export type ReservationTablePreviewData = {
  rules: SlotRule[];
};

export const isReservationTablePreviewData = (
  data: any
): data is ReservationTablePreviewData => {
  return data?.rules && Array.isArray(data.rules);
};

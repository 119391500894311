/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import useReactRouter from 'use-react-router';
import { PublicShopSetting } from '../../../@interfaces/shop-setting';
import { PublicWorkspaceSetting } from '../../../@interfaces/workspace-setting';
import useCourses from '../../../api/use-courses';
import { useCustomDomainAttachedShop } from '../../../api/use-custom-domain-attached-shop';
import { BannerImageContent } from '../../../components/BannerImageContent';
import { DomainError, validDomain } from '../../../components/DomainError';
import { PageNotFoundInfo } from '../../../components/PageNotFoundInfo';
import { Loading } from '../../../components/Shared';
import { useTrackLocation } from '../../../hooks/use-track-location';
import useUserScript from '../../../hooks/use-user-script';
import { DEFAULT_PRIMARY_COLOR, LOGO_BASE_URL } from '../../../models/theme';
import { getInflowSource } from '../../../utils/inflow-source';
import { useConversionFunnel } from '../../insight/hooks/useConversionFunnel';
import Courses from '../components/Courses';
import Description from '../components/Description';

const styles = {
  maxWidth: css`
    max-width: 700px;
    margin: 0 auto;
  `,
  logo: css`
    display: block;
    width: 50%;
    margin: 0 auto;
    padding: 32px;
    & img {
      width: 100%;
    }
  `,
  title: css`
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  `,
  text: css`
    text-align: center;
    font-size: 14px;
  `,
  shopLogoWrapper: css`
    width: 100%;
  `,
  workspaceLogoWrapper: css`
    padding: 48px 0;
    width: 55%;
    max-width: 300px;
    margin: 0 auto;
  `,
  firstLogo: css`
    width: 100%;
  `,
  shopName: css`
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    line-height: 150%;
  `,
  headline: css`
    font-size: 14px;
    white-space: break-spaces;
    word-break: break-word;
    margin: 0 auto;
  `,
};

type PageParams = {
  workspaceUid: string;
  shopUid: string;
};

export default function ShopCoursesPage() {
  useTrackLocation();
  const { location, match } = useReactRouter<PageParams>();
  const { workspaceUid, shopUid } = match.params;

  const {
    workspace,
    workspaceSetting,
    shop,
    shopSetting,
    courses,
    isLoadingCourses,
  } = useCourses(workspaceUid, shopUid || '');

  useConversionFunnel(workspaceUid, shopUid, getInflowSource(location.search));

  const { data: shopCustomDomain, isLoading: isLoadingShopCustomDomain } =
    useCustomDomainAttachedShop(workspaceUid, shopUid);

  useUserScript(workspaceSetting, shopSetting);

  const primaryColor =
    shopSetting?.primaryColor ||
    workspaceSetting?.primaryColor ||
    DEFAULT_PRIMARY_COLOR;

  if (
    !isLoadingCourses &&
    !validDomain(workspaceSetting?.domain) &&
    !isLoadingShopCustomDomain &&
    !validDomain(shopCustomDomain?.fqdn ?? null)
  ) {
    return (
      <DomainError
        domain={workspaceSetting?.domain ?? shopCustomDomain?.fqdn}
      />
    );
  }

  return isLoadingCourses ? (
    <div>
      <Loading primaryColor={primaryColor} />
    </div>
  ) : (
    <div css={styles.maxWidth}>
      {courses ? (
        <div
          css={css`
            background: #f2f2f2;
            padding-bottom: 56px;
          `}
        >
          <ShopImageContent
            shopSetting={shopSetting}
            workspaceSetting={workspaceSetting}
          />
          <div
            css={css`
              padding: 0 24px;
              margin-top: 4px;
            `}
          >
            <h1 css={styles.shopName}>{shop?.name}</h1>
            <Description
              description={`${
                shopSetting?.headline1 || workspaceSetting?.headline1
              }
${shopSetting?.headline2 || workspaceSetting?.headline2}`}
              enableShowButton={false}
              primaryColor={primaryColor}
            />
          </div>
          <div>
            <Courses
              workspace={workspace!}
              primaryColor={primaryColor}
              shop={shop!}
              courses={courses}
              menuSetting={shopSetting?.menuSetting!}
            />
          </div>
          {shopSetting?.displayBanner &&
          shopSetting?.bannerPath &&
          shopSetting?.displayCourseBanner ? (
            <div
              css={css`
                padding: 0 20px;
                margin: auto;
                display: block;
              `}
            >
              <BannerImageContent shopSetting={shopSetting} />
            </div>
          ) : null}
        </div>
      ) : (
        <PageNotFoundInfo target={courses}>
          存在しない、または非公開な店舗です。
        </PageNotFoundInfo>
      )}
    </div>
  );
}

const ShopImageContent = ({
  shopSetting,
  workspaceSetting,
}: {
  shopSetting?: PublicShopSetting;
  workspaceSetting?: PublicWorkspaceSetting;
}) => {
  if (shopSetting?.images && shopSetting.images.length > 0) {
    return (
      <Splide
        options={{
          type: 'loop',
          rewind: true,
          autoplay: true,
          interval: 5000,
          arrows: shopSetting.images.length > 1,
          pagination: shopSetting.images.length > 1,
          drag: shopSetting.images.length > 1,
        }}
      >
        {shopSetting.images.map((image, index) => (
          <SplideSlide key={`slide-${index}`}>
            <img src={image.url} css={styles.firstLogo} alt="shop slide" />
          </SplideSlide>
        ))}
      </Splide>
    );
  }

  if (shopSetting?.imagePath || workspaceSetting?.imagePath) {
    return (
      <div css={styles.shopLogoWrapper}>
        <img
          src={`${LOGO_BASE_URL}/${
            shopSetting?.imagePath || workspaceSetting?.imagePath
          }`}
          alt=""
          css={styles.firstLogo}
        />
      </div>
    );
  }
  return null;
};

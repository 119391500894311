/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import useReactRouter from 'use-react-router';
import useCourseFormSetting from '../../../api/use-course-form-setting';
import { useCustomDomainAttachedShop } from '../../../api/use-custom-domain-attached-shop';
import useReservation from '../../../api/use-reservation';
import { DomainError, validDomain } from '../../../components/DomainError';
import {
  Header,
  Loading,
  SelectedReserveInfo,
} from '../../../components/Shared';
import { useTrackLocation } from '../../../hooks/use-track-location';
import useUserScript from '../../../hooks/use-user-script';
import { getLineSessionKeyFromParam } from '../../../models/line';
import { DEFAULT_PRIMARY_COLOR } from '../../../models/theme';
import CancelReservationForm from '../components/CancelReservationForm';

const styles = {
  bodyWrapper: css`
    background: #f2f2f2;
    padding-bottom: 56px;
  `,
  maxWidth: css`
    max-width: 700px;
    margin: 0 auto;
  `,
  title: css`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 24px;
  `,
  reserveCancelTitle: css`
    font-size: 20px;
    font-weight: bold;
    padding: 24px 0 0;
  `,
  cancelMessage: css`
    padding: 32px;
  `,
  cancelDescriptionMessage: css`
    font-weight: bold;
    margin: 1em 0;
  `,
};

type PageParams = {
  workspaceUid: string;
  shopUid: string;
  courseUid: string;
  action: 'form';
};

export default function CancelFormPage() {
  useTrackLocation();
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopUid, courseUid } = match.params;

  const searchParams = new URL(window.location.href).searchParams;
  const reservationKeyParam = searchParams.get('reservationKey');
  const lineSessionKey = getLineSessionKeyFromParam();

  const {
    workspace,
    workspaceSetting,
    shop,
    shopSetting,
    course,
    courseSlotSetting,
    formSetting,
    formHeader,
    formFooter,
    isLoadingCourseFormSetting,
  } = useCourseFormSetting(
    workspaceUid,
    shopUid || '',
    courseUid || '',
    lineSessionKey
  );
  const {
    canUpdateReservationDateTimeFromPublic,
    isCanceled,
    originalDateTime,
  } = useReservation(
    workspaceUid,
    shopUid || '',
    courseUid || '',
    reservationKeyParam
  );

  const { data: shopCustomDomain, isLoading: isLoadingShopCustomDomain } =
    useCustomDomainAttachedShop(workspaceUid, shopUid);

  useUserScript(workspaceSetting, shopSetting);
  const primaryColor =
    shopSetting?.primaryColor ||
    workspaceSetting?.primaryColor ||
    DEFAULT_PRIMARY_COLOR;

  if (isCanceled) {
    return <div css={styles.cancelMessage}>この予約はキャンセル済みです。</div>;
  } else if (!canUpdateReservationDateTimeFromPublic) {
    return (
      <div css={styles.cancelMessage}>
        予約のキャンセル期限を過ぎています。お手数ですが店舗に直接ご連絡ください。
      </div>
    );
  }

  if (
    !isLoadingCourseFormSetting &&
    !validDomain(workspaceSetting?.domain) &&
    !isLoadingShopCustomDomain &&
    !validDomain(shopCustomDomain?.fqdn ?? null)
  ) {
    return (
      <DomainError
        domain={workspaceSetting?.domain ?? shopCustomDomain?.fqdn}
      />
    );
  }

  return isLoadingCourseFormSetting ||
    isLoadingShopCustomDomain ||
    !originalDateTime ? (
    <div>
      <Loading primaryColor={primaryColor} />
    </div>
  ) : (
    <div>
      <div css={[styles.maxWidth, styles.bodyWrapper]}>
        <Header
          logoPath={shopSetting?.logoPath || workspaceSetting?.logoPath}
          shopName={shop?.name || ''}
          primaryColor={primaryColor}
        />
        <div
          css={css`
            padding: 0 24px;
          `}
        >
          <h2 css={styles.reserveCancelTitle} style={{ color: primaryColor }}>
            予約をキャンセルする
          </h2>
          <div>
            <SelectedReserveInfo
              name={course?.name || ''}
              imagePath={course?.imagePath || null}
              dateTime={originalDateTime}
              primaryColor={primaryColor}
            />
          </div>
          {courseSlotSetting?.cancelMessage && (
            <p css={styles.cancelDescriptionMessage}>
              {courseSlotSetting.cancelMessage}
            </p>
          )}
          <CancelReservationForm
            workspace={workspace}
            shop={shop}
            shopSetting={shopSetting}
            course={course}
            dateTime={originalDateTime}
            formSetting={formSetting}
            formHeader={formHeader}
            formFooter={formFooter}
            primaryColor={primaryColor}
            reservationKey={reservationKeyParam}
          />
        </div>
      </div>
    </div>
  );
}

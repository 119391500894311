import {
  AnyField,
  Field,
  FormResponse,
  UID,
} from '../core/types/reservation-form-types';
import {
  ResourceConstant,
  SelectedOptionalField,
} from '../core/types/reservation-resource-types';
import { WebReservationLimitType } from './shop-setting';

export type DisplayMode = 'default' | 'table';

export type NewCourseSlotSetting = {
  courseId: number;
  shopId: number;
  slotSettingId: number;
  published: boolean;
  listing: boolean;
  shopCourseSetting: ShopCourseSetting | undefined;
  resourceConstantSetting: ResourceConstantSetting | undefined;
  description: string;
  formHeader: string;
  formFooter: string;
  thanksTitle: string | null;
  thanksMessage: string | null;
  webReservationLimitType: WebReservationLimitType;
  webReservationLimitDays: number | undefined;
  webReservationLimitHours: number | undefined;
  webReservationAdvanceDays: number | undefined;
  overrideWebReservationLimit: boolean;
  displayMode: DisplayMode;
  displayDays?: number; // Web予約画面での予約枠の表示日数(displayMode=defaultの場合のみ有効)
  showsSlotCapacity?: 0 | 1;
  hidesNoSlotDate: boolean;
  cancelMessage?: string;
};

/**
 * 予約制限
 */
export type WebReservationLimit = Pick<
  NewCourseSlotSetting,
  | 'webReservationLimitType'
  | 'webReservationLimitDays'
  | 'webReservationLimitHours'
  | 'webReservationAdvanceDays'
>;

export type CourseSlotSetting = NewCourseSlotSetting & {
  id: number;
  sortOrder?: number | null;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
};

// 店舗でコースの設定をカスタムする場合の設定

export type ShopCourseSetting = {
  minutesRequired: number | undefined;
  fields: FormField[];
  showsMinutesRequired?: boolean;
};

export type FormField = {
  uid: UID;
  options: FormOption[];
};

export type FormOption = {
  uid: UID;
  minutesRequired: number | undefined;
};

export type ForceMinutesRequireds = { [courseId: number]: number };

export const getMinitesRequiredFields = (
  fields: AnyField[],
  shopCourseSetting?: ShopCourseSetting
): AnyField[] => {
  const minutesRequired = shopCourseSetting?.minutesRequired;
  if (!minutesRequired) {
    return [];
  }
  return fields.filter((field) => {
    if (field.type !== 'checkbox' && field.type !== 'radio') {
      return false;
    }
    const formField = shopCourseSetting?.fields.find(
      (f) => f.uid === field.uid
    );
    return (
      formField?.options
        // 削除されたオプションは除外
        .filter(
          (o) => field.options.find((op) => op.uid === o.uid) !== undefined
        )
        .find((o) => o.minutesRequired !== undefined && o.minutesRequired > 0)
    );
  });
};

export const calcTotalMinutesReqired = (
  formResponse: FormResponse,
  shopCourseSetting: ShopCourseSetting | undefined
): number | undefined => {
  const minutesRequired = shopCourseSetting?.minutesRequired;
  // オプションの選択状態により追加の所要時間を計算
  const optionMinutesRequired = calcOptionsMinutesRequired(
    formResponse,
    shopCourseSetting
  );
  const totalMinutesRequired = minutesRequired
    ? minutesRequired + optionMinutesRequired
    : undefined;
  return totalMinutesRequired;
};

/**
 * フォームの入力値と設定を元にオプショナルで追加される所要時間の合計を計算します。
 * @param formResponse フォームの入力値
 * @param shopCourseSetting 店舗のコース設定
 * @returns オプショナルで追加される所要時間の合計
 */
export const calcOptionsMinutesRequired = (
  formResponse: FormResponse,
  shopCourseSetting: ShopCourseSetting | undefined
): number => {
  return calcSelectedOptionalFields(formResponse, shopCourseSetting).reduce(
    (sum, next) => {
      return sum + next.minuteRequired;
    },
    0
  );
};

/**
 * フォームの入力値と設定を元にオプショナルで追加される所要時間を計算します。
 * @param formResponse フォームの入力値
 * @param shopCourseSetting 店舗のコース設定
 * @returns フォームの項目ごとの追加される所要時間。追加がない項目はこのリストには含まれません。
 */
export const calcSelectedOptionalFields = (
  formResponse: FormResponse,
  shopCourseSetting: ShopCourseSetting | undefined
): SelectedOptionalField[] => {
  if (!shopCourseSetting || shopCourseSetting.fields.length === 0) {
    return [];
  }
  return shopCourseSetting.fields.flatMap((formField) => {
    const values = formResponse.fields.find(
      (f) => f.uid === formField.uid
    )?.values;
    if (!values || values.length === 0) {
      return [];
    }
    const targetOptions = formField.options.filter(
      (o) =>
        o.minutesRequired &&
        values.some((v) => typeof v !== 'string' && v.uid === o.uid)
    );
    const minuteRequired = targetOptions.reduce((sum, next) => {
      return sum + (next.minutesRequired || 0);
    }, 0);
    if (minuteRequired === 0) {
      return [];
    }
    return [
      {
        fieldUid: formField.uid,
        minuteRequired,
      },
    ];
  });
};

export type ResourceConstantSetting = {
  constants: ResourceConstant[];
};

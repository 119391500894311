/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useHistory } from 'react-router';
import { CourseSlotSetting } from '../../../@interfaces/course-slot-setting';
import LeftArrow from '../../../image/left_arrow.svg';
import { margeParams } from '../../../utils/query-storage';

const styles = {
  courseChangeButton: css`
    font-size: 14px;
    border-radius: 32px;
    color: #172b4d;
    background-color: #ffffff;
    border: none;
    padding: 3px 6px;
    cursor: pointer;
    img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 6px;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
    &:hover {
      color: #ffffff;
      background-color: #172b4d;
    }
  `,
};

type BackButtonProps = {
  workspaceUid: string;
  shopUid: string;
  courseSlotSetting: CourseSlotSetting | undefined;
  widgetMode: boolean;
};

/**
 * 前の画面に戻るボタン
 */
export const BackButton = ({
  workspaceUid,
  shopUid,
  courseSlotSetting,
  widgetMode,
}: BackButtonProps): JSX.Element | null => {
  const history = useHistory();

  // 一覧に表示しないコース or ウィジェットモードの際 or 予約変更時/キャンセル時は一覧に戻るボタンは表示しない
  if (!!courseSlotSetting?.listing === false || widgetMode) {
    return null;
  }

  const handleChangeCourse = () => {
    const url = new URL(
      margeParams(
        `${window.location.origin}/a/${workspaceUid}/shops/${shopUid}`
      )
    );
    const params = new URLSearchParams(window.location.search);
    const group = params.get('group');
    if (group) {
      url.searchParams.set('group', group);
    }
    history.push(`${url.pathname}${url.search}`);
  };

  return (
    <div
      css={css`
        padding: 0 16px;
        margin-top: 10px;
      `}
    >
      <button css={styles.courseChangeButton} onClick={handleChangeCourse}>
        <img src={LeftArrow} alt="" />
        <span>一覧に戻る</span>
      </button>
    </div>
  );
};
